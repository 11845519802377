import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Page, LegacyCard, Layout, Badge } from "@shopify/polaris";
import { ContextualSaveBar } from "@shopify/app-bridge-react";
import { updateHorseVariant, updateHorseInventoryLevels, getHorseVariant } from "../../../api_utils/requests";
import { ProfilePicture } from "./support/ProfilePicture";
import { Inventory } from "./support/Inventory";
import { Pricing } from "./support/Pricing";
import { ProductOrganization } from "./support/ProductOrganization";
import { LinkedVariants } from "./support/LinkedVariants";
import { Quantity } from "./support/Quantity";
import TagCheckList from "../../common/Filters/TagCheckList";
import { Title } from "./support/Title";
import { Weight } from "./support/Weight";
import { ProductAvailability } from "./support/ProductAvailability";
import { ErrorBanner } from "../../common/ErrorBanner";
import { Footer } from "../../common/Footer";
import SettingToggle from "../../common/Settingstoggle";
import type { IHorseVariant, HorseInventoryLevel, ShopifyVariant } from "../../../api_utils/types";
import { HorseVariantShowPageSkeleton } from "../../common/skeletons";
import { HORSE_VARIANT_HELP_PAGE_URL } from "../../../constants";
import {
  assertString,
  extractMessageFromError,
  getIdFromPath,
  softAssertNumber,
} from "../../../helper_functions/utils";
import Toast from "../../common/Toast";

function HorseVariant(): React.JSX.Element {
  const horseVariantId = useMemo(() => getIdFromPath(), []);

  const [pageLoading, setPageLoading] = useState(true);
  const [hvIsDirty, setHvIsDirty] = useState(false);
  const [hilIsDirty, setHilIsDirty] = useState(false);

  const [horseVariant, setHorseVariant] = useState<IHorseVariant>();
  const [originalHorseVariant, setOriginalHorseVariant] = useState<IHorseVariant>();
  const [hils, setHils] = useState<HorseInventoryLevel[]>([]);
  const [originalHils, setOriginalHils] = useState<HorseInventoryLevel[]>([]);
  const [shopifyVariants, setShopifyVariants] = useState<ShopifyVariant[]>([]);
  const [availability, setAvailability] = useState<
    { shopify_domain: string; friendly_name: string; available: boolean }[]
  >([]);

  const [availableQuantities, setAvailableQuantities] = useState<Record<number, string>>(
    hils.reduce((map, { id, available }) => {
      map[id] = assertString(available);
      return map;
    }, {}),
  );
  useEffect(() => {
    setAvailableQuantities(
      hils.reduce((map, { id, available }) => {
        map[id] = assertString(available);
        return map;
      }, {}),
    );
  }, [hils]);

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);

  useEffect(() => {
    Promise.all([getHorseVariant(horseVariantId)])
      .then(
        ([
          {
            horseVariant: updatedHorseVariant,
            shopifyVariants: updatedShopifyVariants,
            horseInventoryLevels,
            availability: updatedAvailability,
          },
        ]) => {
          setHorseVariant(updatedHorseVariant);
          setOriginalHorseVariant(updatedHorseVariant);
          setShopifyVariants(updatedShopifyVariants);
          setHils(horseInventoryLevels);
          setOriginalHils(horseInventoryLevels);
          setAvailability(updatedAvailability);
          setPageLoading(false);
        },
      )
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [horseVariantId]);

  const handleSave = (): void => {
    if (hilIsDirty) {
      const payload = {
        horse_variant_id: horseVariant.id,
        horse_inventory_levels: hils.map((hil) => {
          return {
            ...hil,
            available: softAssertNumber(availableQuantities[hil.id]),
          };
        }),
        tracked: horseVariant.tracked,
      };
      updateHorseInventoryLevels(payload)
        .then((newHils) => {
          setToastMessage("Save successful");
          setHils(newHils);
          setOriginalHils(newHils);
          setHilIsDirty(false);
        })
        .catch((err: unknown) => {
          Rollbar.error(err, payload);
          const newErrorMessage = extractMessageFromError(err);
          setErrorMessage(newErrorMessage);
          setHilIsDirty(true);
        });
    }

    if (hvIsDirty) {
      updateHorseVariant(horseVariant.id, horseVariant)
        .then((updatedHorseVariant) => {
          setToastMessage("Save successful");
          setHorseVariant(updatedHorseVariant);
          setOriginalHorseVariant(updatedHorseVariant);
          setHvIsDirty(false);
        })
        .catch((err: unknown) => {
          Rollbar.error(err, horseVariant);
          const newErrorMessage = extractMessageFromError(err);
          setErrorMessage(newErrorMessage);
          setHvIsDirty(true);
        });
    }
  };

  const handleDiscard = (): void => {
    setHorseVariant(originalHorseVariant);
    setHils(originalHils);
    setHvIsDirty(false);
    setHilIsDirty(false);
  };

  const toggleRecommendations = useCallback(() => {
    setHorseVariant((prevHorseVariant) => ({
      ...prevHorseVariant,
      recommendations_enabled: !prevHorseVariant.recommendations_enabled,
    }));
    setHvIsDirty(true);
  }, []);

  const pageMarkup = horseVariant && (
    <Page
      backAction={{
        url: "/horse_variants",
      }}
      subtitle={horseVariant.variant_title}
      title={horseVariant.title}
      titleMetadata={horseVariant.discarded_at ? <Badge tone="warning">Archived</Badge> : undefined}
    >
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      <ContextualSaveBar
        discardAction={{
          onAction: handleDiscard,
        }}
        saveAction={{
          onAction: handleSave,
        }}
        visible={hvIsDirty || hilIsDirty}
      />
      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.Section variant="oneThird">
          <ProfilePicture horseVariant={horseVariant} />
          <Title horseVariant={horseVariant} />
          <Pricing
            cost={horseVariant.cost}
            horseVariant={horseVariant}
            setCost={(value) => {
              setHvIsDirty(true);
              setHorseVariant({
                ...horseVariant,
                cost: value,
              });
            }}
          />
          <ProductOrganization horseVariant={horseVariant} />
          <Weight
            height={horseVariant.height}
            length={horseVariant.length}
            setHeight={(value) => {
              setHorseVariant({
                ...horseVariant,
                height: value,
              });
            }}
            setIsDirty={setHvIsDirty}
            setLength={(value) => {
              setHorseVariant({
                ...horseVariant,
                length: value,
              });
            }}
            setWeight={(value) => {
              setHorseVariant({
                ...horseVariant,
                weight: value,
              });
            }}
            setWidth={(value) => {
              setHorseVariant({
                ...horseVariant,
                width: value,
              });
            }}
            weight={horseVariant.weight}
            width={horseVariant.width}
          />
          <LegacyCard title="Tags">
            <LegacyCard.Section>
              <TagCheckList disabled selectedOptions={horseVariant.tags} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section variant="oneHalf">
          <Inventory
            horseVariant={horseVariant}
            setWarehouseLocation={(value) => {
              setHorseVariant({
                ...horseVariant,
                warehouse_location: value,
              });
              setHvIsDirty(true);
            }}
          />

          <LegacyCard>
            <LegacyCard.Section>
              <SettingToggle
                description="Recommend ordering this product when it is forecast to go out of stock."
                enabled={horseVariant.recommendations_enabled}
                handleToggle={toggleRecommendations}
                title="Purchase order recommendations"
              />
            </LegacyCard.Section>
          </LegacyCard>

          <LinkedVariants shopifyVariants={shopifyVariants} />

          <Quantity
            availableQuantities={availableQuantities}
            hils={hils}
            horseVariant={horseVariant}
            setAvailableQuantities={(updatedAvailableQuantities) => {
              setHilIsDirty(true);
              setAvailableQuantities(updatedAvailableQuantities);
            }}
            setTracked={(value) => {
              setHilIsDirty(true);
              setHorseVariant({
                ...horseVariant,
                tracked: value,
              });
            }}
          />
          <ProductAvailability channels={availability} />
        </Layout.Section>
      </Layout>
      <Footer pageTitle="variants" url={HORSE_VARIANT_HELP_PAGE_URL} />
    </Page>
  );

  return pageLoading ? <HorseVariantShowPageSkeleton /> : pageMarkup;
}

export default HorseVariant;
