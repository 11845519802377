import React, { useState } from "react";
import { FormLayout, Modal, TextField } from "@shopify/polaris";
import { useShopifyShop } from "../../../api_utils/requests";
import type { IPurchaseOrder } from "../../../api_utils/types";

const validateEmail = (email: string): boolean => {
  return !!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
    String(email).toLowerCase(),
  );
};

function EmailPdfDialog({
  active,
  onSend,
  onClose,
  purchaseOrder,
  setToastMessage,
  setErrorMessage,
}: {
  readonly active: boolean;
  readonly onSend: (purchaseOrderId: number, payload: object) => Promise<any>;
  readonly onClose: () => void;
  readonly purchaseOrder: IPurchaseOrder;
  readonly setToastMessage: (successMessage: string) => void;
  readonly setErrorMessage: (errorMessage: string) => void;
}): React.JSX.Element {
  const { data: shopifyShop } = useShopifyShop();

  const [toEmail, setToEmail] = useState(purchaseOrder.supplier?.email || "");
  const [toError, setToError] = useState("");
  const [replyToEmail, setReplyToEmail] = useState(shopifyShop.email || "");
  const [replyError, setReplyError] = useState("");
  const [emailBody, setEmailBody] = useState(
    `Hi ${
      purchaseOrder.vendor || purchaseOrder.supplier.name
    },\n\nPlease find attached a purchase order.\n\nBest regards,\n${shopifyShop.name}`,
  );
  const subject = `Purchase order ${purchaseOrder.label || purchaseOrder.id} from ${shopifyShop.name}`;

  const handleSend = (): void => {
    if (!toEmail) {
      setToError("Email is required");
      return;
    } else if (!validateEmail(toEmail)) {
      setToError("Invalid email");
      return;
    }

    if (!replyToEmail) {
      setReplyError("Reply to is required");
      return;
    } else if (!validateEmail(replyToEmail)) {
      setReplyError("Invalid email");
      return;
    }

    const payload = {
      subject,
      to: toEmail,
      reply_to: replyToEmail,
      body: emailBody,
    };

    onSend(purchaseOrder.id, payload)
      .then(() => {
        onClose();
        setToastMessage("Email sent successfully");
      })
      .catch((err: unknown): void => {
        Rollbar.error(err, payload);
        setErrorMessage("Failed to send email");
      });
  };

  return (
    <Modal
      onClose={onClose}
      open={active}
      primaryAction={{
        content: "Send",
        onAction: handleSend,
      }}
      secondaryActions={[
        {
          content: "Cancel",
          onAction: onClose,
        },
      ]}
      title="Email purchase order PDF"
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            autoComplete="on"
            error={toError}
            inputMode="email"
            label="To"
            onChange={setToEmail}
            type="email"
            value={toEmail}
          />

          <TextField
            autoComplete="on"
            error={replyError}
            inputMode="email"
            label="Reply to"
            onChange={setReplyToEmail}
            type="email"
            value={replyToEmail}
          />

          <TextField autoComplete="off" disabled label="Subject" value={subject} />

          <TextField autoComplete="off" label="Body" multiline={8} onChange={setEmailBody} value={emailBody} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

export default EmailPdfDialog;
