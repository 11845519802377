import React, { useEffect, useState } from "react";
import { Page, Layout } from "@shopify/polaris";
import { ContextualSaveBar } from "@shopify/app-bridge-react";
import { NewShopForm } from "./support/NewShopForm";
import { CurrencyChange } from "./support/CurrencyChange";
import { InventorySyncOptions } from "./support/InventorySyncOptions";
import { SyncbackOptions } from "./support/SyncbackOptions";
import { CostSyncOptions } from "./support/CostSyncOptions";
import { updateUser, getSettings } from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import { SettingsPageSkeleton } from "../common/skeletons";
import { SETTINGS_HELP_PAGE_URL } from "../../constants";
import Toast from "../common/Toast";
import { extractMessageFromError } from "../../helper_functions/utils";

function Settings(): React.ReactElement {
  const [pageLoading, setPageLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState<{
    id: number;
    currency: string;
    syncLevel: string;
    syncbackSetting: string;
    costsyncSetting: string;
  }>();
  const [currencies, setCurrencies] = useState<string[]>([]);
  const [shopifyShops, setShopifyShops] = useState<
    {
      shopify_domain: string;
      name: string;
    }[]
  >([]);
  const [currency, setCurrency] = useState("");
  const [userSyncLevel, setUserSyncLevel] = useState("");
  const [syncbackSetting, setSyncbackSetting] = useState("");
  const [costsyncSetting, setCostsyncSetting] = useState("");
  const [isDirty, setIsDirty] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);

  useEffect(() => {
    getSettings().then(({ currentUser, currencies, shopify_shops }) => {
      setCurrentUser(currentUser);
      setCurrencies(currencies);
      setShopifyShops(shopify_shops);

      setCurrency(currentUser.currency);
      setUserSyncLevel(currentUser.syncLevel);
      setSyncbackSetting(currentUser.syncbackSetting);
      setCostsyncSetting(currentUser.costsyncSetting);

      setPageLoading(false);
    });
  }, []);

  const handleSave = (): void => {
    setIsDirty(false);
    const payload = {
      currency,
      sync_level: userSyncLevel,
      syncback_setting: syncbackSetting,
      costsync_setting: costsyncSetting,
    };
    updateUser(currentUser.id, payload)
      .then((response) => {
        setToastMessage(response.message);
        setCurrency(response.currency);
        setUserSyncLevel(response.sync_level);
        setSyncbackSetting(response.syncback_setting);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      });
  };

  const handleDiscard = (): void => {
    setCurrency(currentUser.currency);
    setUserSyncLevel(currentUser.syncLevel);
    setSyncbackSetting(currentUser.syncbackSetting);
    setIsDirty(false);
  };

  const handleSyncOptionChange = (_checked: boolean, newValue: string): void => {
    setUserSyncLevel(newValue);
    setIsDirty(true);
  };

  const handleSyncbackOptionChange = (_checked: boolean, newValue: string): void => {
    setSyncbackSetting(newValue);
    setIsDirty(true);
  };

  const handleCostsyncOptionChange = (_checked: boolean, newValue: string): void => {
    setCostsyncSetting(newValue);
    setIsDirty(true);
  };

  const handleCurrencyChange = (value: string): void => {
    setCurrency(value);
    setIsDirty(true);
  };

  const pageMarkup = currentUser && (
    <Page title="Settings">
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      <ContextualSaveBar
        discardAction={{
          onAction: handleDiscard,
        }}
        saveAction={{
          onAction: handleSave,
        }}
        visible={isDirty}
      />
      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.AnnotatedSection
          description="This is the currency that all values will appear as."
          title="Currency option"
        >
          <CurrencyChange currencies={currencies} currency={currency} handleCurrencyChange={handleCurrencyChange} />
        </Layout.AnnotatedSection>
        <br />
        <Layout.AnnotatedSection description="Choose how Horse should sync to Shopify" title="Sync options">
          <InventorySyncOptions
            handleSyncOptionChange={handleSyncOptionChange}
            shopifyShops={shopifyShops}
            userSyncOption={userSyncLevel}
          />
          <SyncbackOptions handleSyncOptionChange={handleSyncbackOptionChange} syncbackSetting={syncbackSetting} />
          <CostSyncOptions costsyncSetting={costsyncSetting} handleSyncOptionChange={handleCostsyncOptionChange} />
        </Layout.AnnotatedSection>
        <br />
        <Layout.AnnotatedSection
          description="Connect multiple stores for them to share inventory."
          title="Connected stores"
        >
          <NewShopForm currentUser={currentUser} shopifyShops={shopifyShops} />
        </Layout.AnnotatedSection>
        <br />
      </Layout>
      <Footer pageTitle="settings" url={SETTINGS_HELP_PAGE_URL} />
    </Page>
  );

  return pageLoading ? <SettingsPageSkeleton /> : pageMarkup;
}

export default Settings;
