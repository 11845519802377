import React, { useState, useCallback, useMemo } from "react";
import {
  LegacyCard,
  Page,
  Badge,
  TextField,
  Label,
  Button,
  FormLayout,
  PageActions,
  LegacyStack,
  IndexTable,
  Layout,
} from "@shopify/polaris";
import { ContextualSaveBar, Loading } from "@shopify/app-bridge-react";
import { ExportMinor, ImportMinor } from "@shopify/polaris-icons";
import CustomDatePicker from "../common/CustomDatePicker/CustomDatePicker";
import {
  deleteTransferOrder,
  updateTransferOrder,
  importTransferOrderLineItems,
  downloadFile,
} from "../../api_utils/requests";
import ConfirmationDialog from "../common/ConfirmationDialog";
import DeleteConfirmationDialog from "../common/DeleteConfirmationDialog";
import { ImportFile } from "../common/ImportFile";
import HorseVariant from "../common/HorseVariant/HorseVariant";
import { ErrorBanner } from "../common/ErrorBanner";
import {
  addGlowAnimation,
  assertNumber,
  assertString,
  extractMessageFromError,
  humanDateFormat,
  makeLineItemsMap,
} from "../../helper_functions/utils";
import { Footer } from "../common/Footer";
import type { HorseLocation, TransferOrder, TransferOrderLineItem } from "../../api_utils/types";
import { TRANSFER_ORDER_HELP_PAGE_URL } from "../../constants";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import Toast from "../common/Toast";
import Scanner from "../common/Scanner";

interface TransferOrderLineItems {
  number: TransferOrderLineItem;
}

const columnNames = [
  { title: "Variant" },
  { title: "Warehouse location" },
  { title: "Sent" },
  { title: "Received" },
] satisfies NonEmptyArray<IndexTableHeading>;

const getPayload = (transferOrderLineItems: TransferOrderLineItems) =>
  Object.values(transferOrderLineItems).map((transferOrderLineItem) => ({
    id: transferOrderLineItem.id,
    quantity_received: transferOrderLineItem.quantity_received,
  }));

const Index = ({
  origin,
  destination,
  transferOrder: initialTransferOrder,
  refetch,
  lineItems,
}: {
  origin: HorseLocation;
  destination: HorseLocation;
  transferOrder: TransferOrder;
  refetch: () => void;
  lineItems: TransferOrderLineItem[];
}): React.ReactElement => {
  const today = useMemo(() => new Date(), []);
  const tohv = makeLineItemsMap(lineItems);
  const [transferOrderHorseVariants, setTransferOrderHorseVariants] = useState<TransferOrderLineItems>(tohv);
  const [originalTransferOrderHorseVariants, setOriginalTransferOrderHorseVariants] =
    useState<TransferOrderLineItems>(tohv);
  const [transferOrder, setTransferOrder] = useState<TransferOrder>(initialTransferOrder);
  const transferOrderSentDate = transferOrder.sent_date ? new Date(transferOrder.sent_date) : undefined;
  const [originalTransferOrder, setOriginalTransferOrder] = useState<TransferOrder>(initialTransferOrder);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [importCSVModalIsOpen, setImportCSVModalIsOpen] = useState(false);
  const [markAsReceivedModalActive, setMarkAsReceivedModalActive] = useState(false);
  const [deletionDialogueActive, setDeletionDialogueActive] = useState(false);

  const [errorBannerMessage, setErrorBannerMessage] = useState("");

  const [toastMessage, setToastMessage] = useState<string>(null);
  const [toastErrorMessage, setToastErrorMessage] = useState<string>(null);

  const handleMarkAsReceived = (): void => {
    setLoading(true);
    Object.values(transferOrderHorseVariants).forEach((transferOrderHorseVariant) => {
      return (transferOrderHorseVariant.quantity_received ||= 0);
    });

    const payload = {
      transfer_order: {
        transfer_order_horse_variants_attributes: getPayload(transferOrderHorseVariants),
        state: "received",
      },
    };
    updateTransferOrder(transferOrder.id, payload)
      .then((): void => {
        setToastMessage("Save successful");
        refetch();
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorBannerMessage(message);
      })
      .finally(() => {
        setLoading(false);
        setIsDirty(false);
      });
  };

  const handleSave = useCallback((): void => {
    setLoading(true);
    const payload = {
      transfer_order: {
        transfer_order_horse_variants_attributes: getPayload(transferOrderHorseVariants),
        label: transferOrder.label,
      },
    };
    updateTransferOrder(transferOrder.id, payload)
      .then((): void => {
        setToastMessage("Save successful");
        setOriginalTransferOrder({ ...transferOrder, label: transferOrder.label });
        setOriginalTransferOrderHorseVariants({ ...transferOrderHorseVariants });
      })
      .catch((err: unknown) => {
        Rollbar.error(err, payload);
        const message = extractMessageFromError(err);
        setErrorBannerMessage(message);
      })
      .finally(() => {
        setLoading(false);
        setIsDirty(false);
      });
  }, []);

  const handleDiscardAction = useCallback((): void => {
    setIsDirty(false);
    setTransferOrder(originalTransferOrder);
    setTransferOrderHorseVariants(originalTransferOrderHorseVariants);
  }, []);

  const handleReceiveAll = (): void => {
    const newQuantity = Object.values(transferOrderHorseVariants).reduce((acc, transferOrderHorseVariant) => {
      acc[transferOrderHorseVariant.id] = {
        ...transferOrderHorseVariant,
        quantity_received: transferOrderHorseVariant.quantity_sent,
      };
      return acc;
    }, {});
    setTransferOrderHorseVariants(newQuantity);
    setIsDirty(true);
  };

  const handleQuantityChange =
    (transferOrderHorseVariant) =>
    (quantity_received, id): void => {
      setTransferOrderHorseVariants({
        ...transferOrderHorseVariants,
        [id]: { ...transferOrderHorseVariant, quantity_received },
      });
      setIsDirty(true);
    };

  const handleLabelChange = useCallback((value: string): void => {
    setTransferOrder({ ...transferOrder, label: value });
    setIsDirty(true);
  }, []);

  const tohvArray: TransferOrderLineItem[] = Object.values(transferOrderHorseVariants);
  const total = tohvArray.reduce(
    (acc, item) => {
      acc.quantity_sent += assertNumber(item.quantity_sent);
      acc.quantity_received += assertNumber(item.quantity_received);
      return acc;
    },
    { quantity_received: 0, quantity_sent: 0 },
  );

  const incrementLineItem = useCallback((id: number): void => {
    setTransferOrderHorseVariants((prevLineItems: TransferOrderLineItems) => {
      const existingLineItem: TransferOrderLineItem = prevLineItems[id];
      const existingQuantity = existingLineItem.quantity_received || 0;
      const newQuantity = existingQuantity + 1;
      const updatedLineItem: TransferOrderLineItem = {
        ...existingLineItem,
        quantity_received: newQuantity,
      };

      return {
        ...prevLineItems,
        [id]: updatedLineItem,
      };
    });
    setIsDirty(true);

    addGlowAnimation(`input[name="quantity_received][id="${id}"]`);
  }, []);

  const pageMarkup = transferOrder && (
    <>
      {loading ? <Loading /> : null}
      <ContextualSaveBar
        discardAction={{
          onAction: handleDiscardAction,
        }}
        saveAction={{
          onAction: handleSave,
        }}
        visible={isDirty}
      />
      <Page
        actionGroups={[
          {
            title: "CSV",
            actions: [
              {
                content: "Import",
                onAction: (): void => {
                  setImportCSVModalIsOpen(true);
                },
                icon: ImportMinor,
              },
              {
                content: "Export",
                onAction: (): void => {
                  const transferOrderName = transferOrder.label || transferOrder.id;
                  const filename = `Horse Transfer Order ${transferOrderName}.csv`;
                  downloadFile(`/transfer_orders/${transferOrder.id}.csv${window.location.search}`, filename);
                },
                icon: ExportMinor,
              },
            ],
          },
        ]}
        backAction={{
          content: "Receive transfer order",
          url: "/transfer_orders",
        }}
        primaryAction={{
          content: "Close transfer order",
          onAction: (): void => {
            setMarkAsReceivedModalActive(true);
          },
        }}
        title="Receive transfer order"
        titleMetadata={<Badge tone="info">Sent</Badge>}
      >
        <ConfirmationDialog
          active={markAsReceivedModalActive}
          cancelText="No"
          content="Are you sure you want to close this transfer order? You cannot undo this."
          okText="Yes"
          onCancel={(): void => {
            setMarkAsReceivedModalActive(false);
          }}
          onClose={(): void => {
            setMarkAsReceivedModalActive(false);
          }}
          onOk={(): void => {
            setMarkAsReceivedModalActive(false);
            handleMarkAsReceived();
          }}
          title="Close transfer order"
        />
        <ImportFile
          apiCallMethod={async (formData) => await importTransferOrderLineItems(transferOrder.id, formData)}
          modalIsOpen={importCSVModalIsOpen}
          setApiResponse={(newTOHV): void => {
            setTransferOrderHorseVariants(makeLineItemsMap(newTOHV));
          }}
          setErrorMessage={setErrorBannerMessage}
          setModalIsOpen={setImportCSVModalIsOpen}
          setToastMessage={setToastMessage}
          title="Import transfer order CSV"
        />
        <Layout>
          {errorBannerMessage ? (
            <Layout.Section>
              <ErrorBanner errorMessage={errorBannerMessage} setErrorMessage={setErrorBannerMessage} />
            </Layout.Section>
          ) : null}
          <Layout.Section>
            <LegacyCard sectioned>
              <LegacyCard.Section title="Origin">
                <Label id="sentTransferOrderOriginNameLabel">{origin.name}</Label>
              </LegacyCard.Section>
              <LegacyCard.Section title="Destination">
                <Label id="sentTransferOrderDestinationNameLabel">{destination.name}</Label>
              </LegacyCard.Section>
              <LegacyCard.Section>
                <FormLayout>
                  <FormLayout.Group>
                    <TextField
                      autoComplete="off"
                      label="Label"
                      onChange={handleLabelChange}
                      placeholder={transferOrder.id.toString()}
                      value={transferOrder.label || ""}
                    />
                  </FormLayout.Group>
                  <FormLayout.Group>
                    <CustomDatePicker
                      date={transferOrderSentDate || today}
                      disabled
                      label="Sent date"
                      placeholder={humanDateFormat(today)}
                      setDate={(): void => {}}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </LegacyCard.Section>
            </LegacyCard>
          </Layout.Section>
          <Layout.Section>
            <LegacyCard>
              <LegacyCard.Section>
                <LegacyStack distribution="trailing">
                  <Scanner
                    incrementLineItem={incrementLineItem}
                    lineItems={transferOrderHorseVariants}
                    setToastErrorMessage={setToastErrorMessage}
                  />
                  <Button onClick={handleReceiveAll} variant="primary">
                    Receive all
                  </Button>
                </LegacyStack>
              </LegacyCard.Section>
              <IndexTable headings={columnNames} itemCount={tohvArray.length} selectable={false}>
                <IndexTable.Row id="sendTransferOrderTotalsRow" key={0} position={0} rowType="subheader">
                  <IndexTable.Cell>Totals</IndexTable.Cell>
                  <IndexTable.Cell />
                  <IndexTable.Cell>{total.quantity_sent}</IndexTable.Cell>
                  <IndexTable.Cell>{total.quantity_received}</IndexTable.Cell>
                </IndexTable.Row>
                {tohvArray.map((variant, index) => (
                  <IndexTable.Row id={variant.id.toString()} key={variant.id} position={index + 1}>
                    <IndexTable.Cell>
                      <HorseVariant key={variant.id} variant={variant} />
                    </IndexTable.Cell>
                    <IndexTable.Cell>{variant.warehouse_location}</IndexTable.Cell>
                    <IndexTable.Cell>
                      <div key={variant.id}>{variant.quantity_sent}</div>
                    </IndexTable.Cell>
                    <IndexTable.Cell>
                      <div className="w120" key={variant.id}>
                        <TextField
                          autoComplete="off"
                          id={variant.id.toString()}
                          key={variant.id}
                          label=""
                          labelHidden
                          name="quantity_received"
                          onChange={handleQuantityChange(variant)}
                          placeholder="0"
                          type="integer"
                          value={assertString(variant.quantity_received || 0)}
                        />
                      </div>
                    </IndexTable.Cell>
                  </IndexTable.Row>
                ))}
              </IndexTable>
            </LegacyCard>
          </Layout.Section>
        </Layout>

        <PageActions
          secondaryActions={[
            {
              content: "Delete",
              destructive: true,
              onAction: (): void => {
                setDeletionDialogueActive(true);
              },
            },
          ]}
        />
        <DeleteConfirmationDialog
          active={deletionDialogueActive}
          content="Are you sure you want to delete this transfer? This action cannot be reversed."
          deleteItem={deleteTransferOrder}
          gotoPath="/transfer_orders"
          itemId={transferOrder.id}
          setActive={setDeletionDialogueActive}
          title="Delete transfer"
        />
        <Footer pageTitle="transfer orders" url={TRANSFER_ORDER_HELP_PAGE_URL} />
        <Toast
          setToastErrorMessage={setToastErrorMessage}
          setToastMessage={setToastMessage}
          toastErrorMessage={toastErrorMessage}
          toastMessage={toastMessage}
        />
      </Page>
    </>
  );

  return pageMarkup;
};

export default Index;
